import { useNavigate } from "react-router-dom";
import Button from "../component/Button";
import Header from "../component/Header";
import Editor from "../component/Editor"
import { useContext } from "react";
import { DiaryDispatchContext } from "../App";


const New = () => {
    /* 구조 분해 할당 하는 이유는 콘텍스트에는 3개 함수(객체)가 있기 때문에 객체를 구조 분해 할당해서 원하는 함수만 가져온다. */    
    const { onCreate } = useContext(DiaryDispatchContext);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    const onSubmit = (data) => {
        const {date, content, emotionId } = data;
        onCreate(date, content, emotionId);
        navigate("/", { replace: true});
    };


    return (
        <div>
            <Header
                title={"새 일기 쓰기"}
                leftChild={<Button text={"< 뒤로가기"} onClick={goBack} />}
            />
            <Editor onSubmit={onSubmit} />
        </div>
    )
}
export default New;