import React, { useState } from 'react';
import './ck_styles.css'; // CSS 파일 임포트
import emotion5 from "../img/emotion5.png";

const ImageAnimation = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="image-container">
      <img
        src={emotion5}
        alt="Your Image"
        className={isHovered ? "hovered" : ""}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
    </div>
  );
};

export default ImageAnimation;
