import { useState, useEffect, useCallback } from "react";
import "./Editor.css";
import { emotionList, getFormattedDate } from "../util";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import EmotionItem from "./EmotionItem";

/* ---------------------------------------- */
import ImageAnimation from "./ImageAnimation";
/* ---------------------------------------- */

const Editor = ({ initData, onSubmit }) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        date: getFormattedDate(new Date()),
        emotionId: 3,
        content: "",
    });
    const handleChangeDate = (e) => {
        setState({
            ...state,
            date: e.target.value,
        });
    };
    const handleChangeContent = (e) => {
        setState({
            ...state,
            content: e.target.value,
        });
    };
    const handleSubmit = () => {
        onSubmit(state);
    }
    const handleOnGoBack = () => {
        navigate(-1);
    }
    // const handleChangeEmotion = (emotionId) => {
    //     setState({
    //         ...state,
    //         emotionId,
    //     });
    // };
    // ↓ 개선
    // useCallback 으로 함수 handleChangeEmotion 을 Editor 컴포넌트의 마운트 시점 이후에는 다시 생성되지 않도록 메모이제이션한다.
    // const handleChangeEmotion = useCallback((emotionId) => {
    //     setState({
    //         ...state,
    //          emotionId,
    //     });
    // }, []);
    // ↓ 개선
    // 문제점 : setState 에서 참조하는 state 의 값이 마운트 이후 변하지 않기 때문에 State 의 최신값을 유지할 수 없어 정상적으로 상태가 업데이트되지 않는다. 
    // 해결 : 함수형 업데이트 : setState의 인수로 값이 아닌 함수를 전달한다. 
    const handleChangeEmotion = useCallback((emotionId) => {
        setState((state) => ({
            ...state,
            emotionId,
        }));
    }, []);

    useEffect(() => {
        if (initData) {
            setState({
                ...initData,
                date: getFormattedDate(new Date(parseInt(initData.date))),
            });
        }
    }, [initData])

    return (
        <div className="Editor">
            <div className="editor_section">
                {/* 날짜 */}
                <h4>오늘의 날짜</h4>
                <div className="input_wrapper">
                    <input type="date" value={state.date}
                        onChange={handleChangeDate} />
                </div>
            </div>
            <div className="editor_section">
                {/* 감정 */}
                <h4>오늘의 감정</h4>
                <div className="input_wrapper emotion_list_wrapper">
                    {emotionList.map((it) => (
                        <EmotionItem
                            key={it.id}
                            {...it}
                            onClick={handleChangeEmotion}
                            isSelected={state.emotionId == it.id}
                        />
                        /* <img key={it.id} alt={`emotion${it.id}`} src={it.img} /> */
                    ))}
                </div>
            </div>
            <div className="editor_section">
                {/* 일기 */}
                <h4>오늘의 일기</h4>
                <div className="input_wrapper">
                    <textarea
                        placeholder="오늘은 어땠나요?"
                        value={state.content}
                        onChange={handleChangeContent}
                    />
                </div>
            </div>
            <div className="editor_section">
                {/* 작성 완료, 취소 */}                
            </div>
            <div className="editor_section button_section">
                <Button text={"취소하기"} onClick={handleOnGoBack} />
                <Button text={"작성 완료"} type={"positive"} onClick={handleSubmit} />
            </div>

            <div className="editor_section">
                {/* 날짜 */}
                <hr />
                <h4>TEST - 애니메이션</h4>
                <ImageAnimation />
            </div>                
        </div>

    )
}
export default Editor;